<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
import { defualtMeta } from '@/utils/meta';

export default {
	metaInfo: {
		title: '아이알리미 아이앱',
		meta: defualtMeta,
	},
	name: 'app',
};
</script>

<style>
@import './assets/css/common.css';
</style>
