import store from '@/store/index';
import router from '@/router/index';
import { saveAccessTokenToCookie } from '@/utils/cookies';
import { deleteCookie } from '@/utils/cookies';
import Swal from 'sweetalert2';
import { sendMessage } from '@/utils/message';
import { sweetAlert } from '@/utils/etc';

export function setInterceptors(instance) {
	// Add a request interceptor
	instance.interceptors.request.use(
		function (config) {
			// Do something before request is sent
			// console.log(config);
			config.headers.accessToken = 'Bearer ' + store.state.auth.accessToken;
			config.headers.refreshToken = 'Bearer ' + store.state.auth.refreshToken;
			return config;
		},
		function (error) {
			// Do something with request error
			return Promise.reject(error);
		},
	);

	// Add a response interceptor
	instance.interceptors.response.use(
		function (response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			if (response.headers && response.headers['accesstoken']) {
				const accessToken = response.headers['accesstoken'];
				if (store.state.accessToken !== accessToken) {
					store.commit('setAccessToken', accessToken);
					saveAccessTokenToCookie(accessToken);
				}
			}
			return response;
		},
		function (error) {
			if ((error.response.status == 403 && error.response.data.resultCode == '-9') || (error.response.status == 401 && error.response.data.resultCode == '-1')) {
				Swal.fire({
					title: '',
					text: error.response.data.resultMsg,
					confirmButtonColor: '#FE8131',
				}).then(result => {
					if (result.isConfirmed) {
						store.commit('clearAccessToken');
						store.commit('clearRefreshToken');
						store.commit('clearUsrId');
						store.commit('clearEmail');
						deleteCookie('accessToken');
						deleteCookie('refreshToken');
						deleteCookie('usrId');
						deleteCookie('email');

						let message = {
							action: 'logout',
						};
						sendMessage('logout', message);

						router.push('/login');
					}
				});
			} else {
				sweetAlert(error.response.status);
			}
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			return Promise.reject(error);
		},
	);
	return instance;
}
