import Swal from 'sweetalert2';

function clickCameraIcon() {
	const selectBox = document.querySelectorAll('.thumb .list_select');
	const selectList = document.querySelectorAll('.thumb .list_select li');

	selectBox[0].style.display = 'block';

	for (var i = 0; i < selectList.length; i++) {
		selectList[i].querySelector('a').onclick = function () {
			const thisSelect = this;
			this.style.backgroundColor = 'rgb(247,247,247)';
			setTimeout(function () {
				selectBox[0].style.display = 'none';
				thisSelect.style.backgroundColor = 'rgb(255,255,255)';
			}, 100);
		};
	}
}

function autoHyphen(value) {
	// value = value.replace(/[^0-9]/g, "");
	// return value.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");

	return value.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
}

function sweetAlert(title) {
	Swal.fire({
		// title: '',
		// html: '<p style="font-size:12px">' + title + '</p>',
		html: title,
		// text: title,
		width: 300,
		confirmButtonText: '확인',
		confirmButtonColor: '#FE8131',
	});
}

function checkMobile() {
	var ua = navigator.userAgent.toLowerCase();
	if (ua.indexOf('kakaotalk') > -1) {
		// 카카오톡
		return 'kakaotalk';
	} else if (ua.indexOf('android') > -1) {
		// 안드로이드
		return 'android';
	} else if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('ipod') > -1) {
		// ios
		return 'ios';
	} else {
		// ios, 안드로이드 외
		return 'other';
	}
}

export { clickCameraIcon, autoHyphen, sweetAlert, checkMobile };
