import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	routes: [
		// 초기 진입 페이지
		{ path: '/', redirect: '/login' },

		// 없는 페이지
		{ path: '*', component: () => import('@/components/common/NotFoundPage.vue') },

		// APK 다운로드
		{ path: '/downloadApk', name: 'downloadApk', component: () => import('@/views/DownloadApk.vue') },

		// 본인인증 페이지
		{ path: '/niceGuide', name: 'niceGuide', component: () => import('@/views/member/NiceGuide.vue') },
		{ path: '/nice', name: 'nice', component: () => import('@/views/member/Nice.vue') },
		{ path: '/niceComplete', name: 'niceComplete', component: () => import('@/views/member/NiceComplete.vue') },

		// member 폴더
		{ path: '/login', name: 'login', component: () => import('@/views/member/Login.vue') },
		{ path: '/join', name: 'join', component: () => import('@/views/member/Join.vue') },
		{ path: '/agree', name: 'agree', component: () => import('@/views/member/Agree.vue') },
		{ path: '/auth', name: 'auth', component: () => import('@/views/member/Auth.vue') },
		{ path: '/authCheck', name: 'authCheck', component: () => import('@/views/member/AuthCheck.vue') },
		{ path: '/findEmailComplete', name: 'findEmailComplete', component: () => import('@/views/member/FindEmailComplete.vue') },
		{ path: '/findPassword', name: 'findPassword', component: () => import('@/views/member/FindPassword.vue') },
		{ path: '/findPasswordComplete', name: 'findPasswordComplete', component: () => import('@/views/member/FindPasswordComplete.vue') },

		// guide 폴더
		{ path: '/locationSet', name: 'locationSet', component: () => import('@/views/guide/LocationSet.vue') },
		{ path: '/alarmSet', name: 'alarmSet', component: () => import('@/views/guide/AlarmSet.vue') },
		{ path: '/markSet', name: 'markSet', component: () => import('@/views/guide/MarkSet.vue') },
		{ path: '/completion', name: 'completion', component: () => import('@/views/guide/Completion.vue') },
		{ path: '/childGuide', name: 'childGuide', component: () => import('@/views/guide/ChildGuide.vue') },

		// home 폴더
		{ path: '/home', name: 'home', component: () => import('@/views/home/Home.vue'), meta: { auth: true } },
		{ path: '/sfzApplyConfirm/:encId', name: 'sfzApplyConfirm', component: () => import('@/views/home/SfzApplyConfirm.vue'), meta: { auth: true } },
		{ path: '/sfzApplyComplete', name: 'sfzApplyComplete', component: () => import('@/views/home/SfzApplyComplete.vue'), meta: { auth: true } },
		{ path: '/withdrawalConfirm', name: 'withdrawalConfirm', component: () => import('@/views/home/WithdrawalConfirm.vue'), meta: { auth: true } },

		// setting 폴더
		{ path: '/setting', name: 'setting', component: () => import('@/views/setting/Setting.vue'), meta: { auth: true } },
		{ path: '/alarm', name: 'alarm', component: () => import('@/views/setting/Alarm.vue'), meta: { auth: true } },
		{ path: '/myInfo', name: 'myInfo', component: () => import('@/views/setting/MyInfo.vue'), meta: { auth: true } },
		{ path: '/myInfoModify', name: 'myInfoModify', component: () => import('@/views/setting/MyInfoModify.vue'), meta: { auth: true } },
		{ path: '/linkedCaregiver', name: 'linkedCaregiver', component: () => import('@/views/setting/LinkedCaregiver.vue'), meta: { auth: true } },
		{ path: '/passwordReset', name: 'passwordReset', component: () => import('@/views/setting/PasswordReset.vue'), meta: { auth: true } },
		{ path: '/notice', name: 'notice', component: () => import('@/views/setting/Notice.vue'), meta: { auth: true } },
		{ path: '/noticeDetail', name: 'noticeDetail', component: () => import('@/views/setting/NoticeDetail.vue'), meta: { auth: true } },

		// terms 폴더
		{ path: '/PrivacyConsent', name: 'PrivacyConsent', component: () => import('@/views/terms/PrivacyConsent.vue'), meta: { title: '개인정보 수집 및 이용 동의' } },
		{ path: '/LocationServiceTerms', name: 'LocationServiceTerms', component: () => import('@/views/terms/LocationServiceTerms.vue'), meta: { title: '위치기반 서비스 이용약관' } },
		{ path: '/LocationAlertTerms', name: 'LocationAlertTerms', component: () => import('@/views/terms/LocationAlertTerms.vue'), meta: { title: '아이알리미 위치알림 서비스 이용약관' } },
		{ path: '/UserDataSharingConsent', name: 'UserDataSharingConsent', component: () => import('@/views/terms/UserDataSharingConsent.vue'), meta: { title: '이용자간 개인정보 제공 동의' } },
	],
});

router.beforeEach((to, from, next) => {
	if (to.meta.auth && !store.getters.isLogin) {
		console.log('인증이 필요합니다');
		next('/login');
	}
	next();
});

export default router;
