import { instance } from './index';

// 회원가입
function registUser(userData) {
	// return instance.post('user/signUp', userData);
	return instance.post('user/signUp', userData, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
}

// 로그인
function loginUser(userData) {
	return instance.post('user/signIn', userData);
}

// 인증번호 전송
function sendAuthCode(phn) {
	return instance.post('user/send_auth_code', null, { params: { phn: phn } });
}

// 인증하기
function crtfctAuthCode(phn, authCode) {
	return instance.post('user/crtfct_auth_code', null, { params: { phn: phn, authCode: authCode } });
}

// 이메일주소 찾기
function getEmailAddress(phn) {
	return instance.get('user/getEmailAddress', { params: { phn: phn } });
}

// // 이메일주소로 회원정보 조회
// function getUserInfoByEmail(email) {
// 	return instance.get('user/getUserInfoByEmail', { params: { email: email } });
// }

// 휴대폰번호로 회원정보 조회
function getUserInfoByPhn(phn) {
	return instance.get('user/getUserInfoByPhn', { params: { phn: phn } });
}

// 패스워드 찾기 이메일 발송
function sendEmail(userData) {
	return instance.post('user/sendEmail', userData);
}

export { registUser, loginUser, sendAuthCode, crtfctAuthCode, getEmailAddress, getUserInfoByPhn, sendEmail };
